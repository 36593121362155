@tailwind base;
@tailwind components;
@tailwind utilities;

/* IBKR All */
html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

body {
  scrollbar-color: rgba(0, 0, 0, 0.22) #f9f9f9;
}

/* Custom */
.spinner {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
